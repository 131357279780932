<template>
  <div>
    <van-notice-bar color="#FA5151" background="#FDEAEA" left-icon="info" wrapable :scrollable="false" :text="`驳回原因：${detail.reviewComment}`" v-if="detail.reviewStatus == 13" />
    <div class="i-surveys-result-list">
      <div class="i-surveys-verification-list">
        <div class="verification-title">请上传核销所需图片</div>
        <div class="verification-cont">
          <base-upload v-model="fileUrl" :repeat-upload="false" />
        </div>
      </div>
      <base-footer background-color="#fff">
        <van-row :gutter="20">
          <van-col :span="24">
            <van-button type="primary" block round @click="sbumit" :disabled="!fileUrl.length">提 交</van-button>
          </van-col>
        </van-row>
      </base-footer>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      fileUrl: [],
      detail: {}
    }
  },
  methods: {
    // 获取核销详情
    getDetail() {
      this.$api.surveys.questionAnswerCav(this.$route.params.id).then(res => {
        if (!res.code) {
          this.fileUrl = (res.data || []).map(item => ({
            url: item.imgUrl
          }))
        }
      })
    },
    sbumit() {
      let list = this.fileUrl.map(item => ({
        imgUrl: item.url
      }))
      this.$api.surveys.saveQuestionAnswerCav({
        id: this.$route.params.id,
        list
      }).then(res => {
        if (!res.code) {
          this.$router.go(-1);
        }
      })
    },
    // 获取病例驳回原因
    async getCaseDetail() {
      const res = await this.$api.surveys.asnwerDetail(
        this.$route.params.id
      );
      if (!res.code) {
        this.detail = res.data
      }
    },
  },
  mounted() {
    this.getDetail();
    if (this.$route.query.formType == 'edit') {
      this.getCaseDetail()
    }
  },
}
</script>
<style scoped lang="less">
.i-surveys-verification-list {
  box-sizing: border-box;
  overflow: hidden;
  border-radius: 6px;
  background-color: #fff;
  padding: 14px 16px;
}
.verification-title {
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
  color: #1f2329;
}
.verification-cont {
  padding-top: 14px;
}
</style>
